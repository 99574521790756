import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getPlanoVariacoes(ctx, params) {
      return axios.get(`/plano/${params.nomePlano}/variacao/${params.nomePlanoVariacao}`)
    },

    getPlanoVariacoesRecorrencia(ctx, params) {
      return axios.get(`/plano/${params.nomePlano}/variacao/${params.nomePlanoVariacao}/recorrencia`)
    },

    getFormaPagamento() {
      return axios.get('/checkout/forma-pagamento')
    },

    criar(ctx, payload) {
      return axios.post('/checkout', payload)
    },

    upgrade(ctx, payload) {
      return axios.post('/checkout/upgrade', payload)
    },

    recorrencia(ctx, payload) {
      return axios.post('/checkout/recorrencia', payload)
    },

    credito(ctx, payload) {
      return axios.post('/checkout/credito', payload)
    },

    atualizarSessao() {
      return axios.get('/usuario-info/login')
    },
  },
}
